import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "nucleus-python"
    }}>{`nucleus-python `}<img alt="PyPI" src="https://img.shields.io/pypi/v/nucleus-python" />{` `}<img alt="PyPI - Python Version" src="https://img.shields.io/pypi/pyversions/nucleus-python" /></h1>
    <p>{`We tried to make it as simple as possible to report the data you need to analyze your app usage and improve it.`}</p>
    <p>{`This module is compatible with Python 3+.`}</p>
    <p>{`To start using this module, sign up and get an app ID on `}<a parentName="p" {...{
        "href": "https://nucleus.sh"
      }}>{`Nucleus.sh`}</a>{`. `}</p>
    <h2 {...{
      "id": "installation"
    }}>{`Installation`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ pip install nucleus-python
`}</code></pre>
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <p>{`Add the following code to import Nucleus and init the analytics.`}</p>
    <p>{`Don't use the `}<inlineCode parentName="p">{`import ... from`}</inlineCode>{` syntax as you won't be able to set the module options like `}<inlineCode parentName="p">{`app_id`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`import nucleus

nucleus.app_id = 'your app id'

nucleus.set_props({
    'version': '0.5.0', # Set app version (Nucleus cannot detect it)
    'userId': 'richard_hendrix'
})

nucleus.app_started()
`}</code></pre>
    <p><strong parentName="p">{`Only use `}<inlineCode parentName="strong">{`app_started()`}</inlineCode>{` once per session, if you are using Nucleus in several files call app_started() the earliest possible.`}</strong></p>
    <p>{`Sign up and get a tracking ID for your app `}<a parentName="p" {...{
        "href": "https://nucleus.sh"
      }}>{`here`}</a>{`.`}</p>
    <h3 {...{
      "id": "options"
    }}>{`Options`}</h3>
    <p>{`You can init Nucleus with options:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`nucleus.report_interval = 20 # interval (in seconds) between server com
nucleus.disable_tracking = False # completely disable tracking
nucleus.debug = False # Show internal logs to help debug
nucleus.auto_user_id = False # Assign the user an ID
`}</code></pre>
    <h3 {...{
      "id": "identify-users"
    }}>{`Identify users`}</h3>
    <p>{`You can track specific users actions on the 'User Explorer' section of your dashboard by assigning an user ID. `}</p>
    <p>{`It can be any value as long as it is a `}<em parentName="p">{`string`}</em>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`nucleus.set_user_id('someUniqueUserId')
`}</code></pre>
    <p>{`Alternatively, set the `}<inlineCode parentName="p">{`auto_user_id`}</inlineCode>{` option of the module to `}<inlineCode parentName="p">{`True`}</inlineCode>{` to automatically assign the user an ID based on his username and hostname.`}</p>
    <h3 {...{
      "id": "modify-automatic-data"
    }}>{`Modify automatic data`}</h3>
    <p>{`You can overwrite some properties or fill data that wasn't detected.`}</p>
    <p><em parentName="p">{`You have to do it before calling `}<inlineCode parentName="em">{`app_started()`}</inlineCode>{` for this to work`}</em></p>
    <p>{`It is a good idea to set your app version directly as Nucleus `}<strong parentName="p">{`cannot detect it`}</strong>{` for the moment.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`nucleus.set_props({
    'version': '0.5.0',
    'locale': 'en_US'
})
`}</code></pre>
    <h3 {...{
      "id": "track-custom-data"
    }}>{`Track custom data`}</h3>
    <p>{`You can also add custom data along with the automatic data.`}</p>
    <p>{`Those will be visible in your user dashboard `}<em parentName="p">{`if you previously set an user ID`}</em>{`.`}</p>
    <p>{`The module will remember past properties so you can use `}<inlineCode parentName="p">{`nucleus.set_props`}</inlineCode>{` multiple times without overwriting past props.`}</p>
    <p>{`Properties can either `}<strong parentName="p">{`numbers`}</strong>{`, `}<strong parentName="p">{`strings`}</strong>{` or `}<strong parentName="p">{`booleans`}</strong>{`.
Nested properties or arrays aren't supported at the moment.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`nucleus.set_props({
    "age": 34,
    "name": 'Richard Hendricks',
    "job": 'CEO'
})
`}</code></pre>
    <p>{`To overwrite past properties, set the second parameter to `}<inlineCode parentName="p">{`True`}</inlineCode>{`. `}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`nucleus.set_props({
    "age": 23
}, True)
`}</code></pre>
    <h3 {...{
      "id": "errors"
    }}>{`Errors`}</h3>
    <p>{`To catch errors with Nucleus, simply add the tracking code to an exception and pass the exception as the unique parameter. `}</p>
    <p>{`Nucleus will extract the relevant informations and show them in your dashboard.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`try:
    my_app()
except Exception as e:
    nucleus.track_error(e)
`}</code></pre>
    <p>{`Add this at the outermost level of your code to handle any otherwise uncaught exceptions before terminating.`}</p>
    <p>{`The advantage of except Exception over the bare except is that there are a few exceptions that it wont catch, most obviously KeyboardInterrupt and SystemExit.`}</p>
    <h3 {...{
      "id": "events"
    }}>{`Events`}</h3>
    <p>{`After initializing Nucleus, you can send your own custom events.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`nucleus.track("PLAYED_TRACK")
`}</code></pre>
    <p>{`They are a couple event names that are reserved by Nucleus: `}<inlineCode parentName="p">{`init`}</inlineCode>{`, `}<inlineCode parentName="p">{`error:`}</inlineCode>{` and `}<inlineCode parentName="p">{`nucleus:`}</inlineCode>{`.`}</p>
    <p>{`You shouldn't report events containing these strings.`}</p>
    <h4 {...{
      "id": "tracking-with-data"
    }}>{`Tracking with data`}</h4>
    <p>{`You can add extra information to tracked events, as an object.`}</p>
    <p>{`Properties can either `}<strong parentName="p">{`numbers`}</strong>{`, `}<strong parentName="p">{`strings`}</strong>{` or `}<strong parentName="p">{`booleans`}</strong>{`. `}</p>
    <p>{`Nested properties or arrays aren't supported at the moment.`}</p>
    <p>{`Example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`nucleus.track("PLAYED_TRACK", data = {
    "trackName": 'My Awesome Song',
    "duration": 120
})
`}</code></pre>
    <h3 {...{
      "id": "toggle-tracking"
    }}>{`Toggle tracking`}</h3>
    <p>{`This will completely disable any communication with Nucleus' servers.`}</p>
    <p>{`To opt-out your users from tracking:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`nucleus.disable_tracking()
`}</code></pre>
    <p>{`and to opt back in:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`nucleus.enable_tracking()
`}</code></pre>
    <p>{`This doesn't persist after restarts so you have to handle saving the setting.`}</p>
    <hr></hr>
    <p>{`Contact `}<strong parentName="p"><a parentName="strong" {...{
          "href": "mailto:hello@nucleus.sh"
        }}>{`hello@nucleus.sh`}</a></strong>{` for any inquiry`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      